<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        You are electroplating a maple leaf with
        <number-value :value="mass" unit="\text{g}" />
        of gold from a solution of
        <stemble-latex content="$\ce{Au^3+}.$" />
        When using a
        <stemble-latex content="$10.\,\text{mA}$" />
        current, how long (in seconds) will it take to deposit this amount of gold?
      </p>

      <calculation-input
        v-model="inputs.time"
        prepend-text="$\text{Time}:$"
        append-text="$\text{s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '@/tasks/components/NumberValue.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question508',
  components: {
    StembleLatex,
    NumberValue,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        time: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
  },
};
</script>
